
export default {
  name: "AdsSideLayout",
  computed: {
    showBreadcrumb () {
      return !(['pricing', 'auth', 'checkout', 'index'].some((v) => {
        return this.$route.name ? this.$route.name.includes(v) : false
      }))
    }
  }
}
